import React from "react";
import styled from "styled-components";
import ActionButton from "../../components/ActionButton/ActionButton";

const WrapperButton = styled.div`
  padding-bottom: 10px;
`;

export type ResourceChangeAction = (count: number) => void;

export type HutProps = {
  actions: {
    addWood: ResourceChangeAction;
    burnWood: ResourceChangeAction;
    addFood: ResourceChangeAction;
  };
};

type HutComponent = React.FunctionComponent<HutProps>;

const Forest: HutComponent = ({ actions: { addFood, addWood } }: HutProps) => {
  return (
    <>
      <WrapperButton>
        <ActionButton
          action={() => {
            addWood(2);
          }}
          title="Gather Wood"
          options={{
            animate: true,
            duration: 1.3
          }}
        />
      </WrapperButton>
      <WrapperButton>
        <ActionButton
          action={() => {
            addFood(3);
          }}
          title="Control trap"
          options={{
            animate: true,
            duration: 3
          }}
        />
      </WrapperButton>
    </>
  );
};

export default Forest;
