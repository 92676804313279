import React, { useCallback, useEffect } from "react"
import styled from "styled-components"
import SVGGenerator from "../../components/SVGGenerator/SVGGenerator"
import { IContext, Stores } from "../../context"
import { withContext } from "../../context/Store"
import useKeyEvent, { Keystrokes } from "../../hooks/useKeyEvent"
import { getKeystrokes } from "./MoveLogic"
import { resolveNewAdventurerPosition, runWildTriggers } from "./WildLogic"
import { arrayToPlaygroundMapKey } from "../../components/SVGGenerator/utils"
import AdventureBar from "../../components/AdventureBar/AdventureBar"
import { GroundType } from "../../components/SVGGenerator/enums"

export type ResourceChangeAction = (count: number) => void

export type PlanetProps = IContext & {
  mapSize: [number, number]
}

type HutComponent = React.FunctionComponent<PlanetProps>

const Map: HutComponent = ({ context, mapSize }: PlanetProps) => {
  const map = context[Stores.map]
  const adventurer = context[Stores.adventurer]
  let keystrokes: Keystrokes = getKeystrokes()
  useKeyEvent(keystrokes, context)
  useEffect(() => {
    if (map.get) {
      const newField = map.get(arrayToPlaygroundMapKey(adventurer.hashPosition))

      resolveNewAdventurerPosition(newField, context)
      runWildTriggers(newField, context)
    }
  }, [adventurer.hashPosition])

  return (
    <div>
      <div style={{}}>
        <AdventureBar />
      </div>
      <SVGGenerator
        data={map}
        offset={[15, 15]}
        size={{ width: mapSize[0], height: mapSize[1] }}
        pointer={{
          char: adventurer.char,
          position: adventurer.hashPosition
        }}
      />
    </div>
  )
}

export default withContext<any>(Map)
