type Rule = { x: number; y: number }

export const baseRules: Rule[] = [
  { x: -1, y: 0 },
  { x: 0, y: -1 },
  { x: -1, y: -1 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: 1, y: -1 }
]

//
// vim jak hledat - proste po radcich, najit levyhorni okraj a vzdy radek = dvakrat okraj plus prostedni stejny pocet radku a vymyzat
//

/* export const baseRules2: Rule[] = [
  { x: -1, y: 0 },
  { x: 0, y: -1 },
  { x: -1, y: -1 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: 1, y: 1 },

  { x: 2, y: 2 },
  { x: 0, y: -2 },
  { x: -1, y: -1 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: 1, y: 1 }
] */

const testItem = { x: -1, y: -1 }

export const prepareDiscoverRules = (range: number): Rule[] => {
  let newRules: Rule[] = []

  if (range == 1) return baseRules

  const first = { x: -1 * range, y: -1 * range }

  const count = 2 * range + 1

  for (let iX = 0; iX < count; iX++) {
    for (let iY = 0; iY < count; iY++) {
      newRules.push({ x: first.x + iX, y: first.y + iY })
    }
  }

  newRules.splice(newRules.length / 2, 1)
  return newRules
}
