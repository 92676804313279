import { IStore, Stores } from "../../context"
import { arrayToPlaygroundMapKey } from "../../components/SVGGenerator/utils"
import { Keystrokes, ArrowKeyname } from "../../hooks/useKeyEvent"

export enum ArrowKey {
  ArrowLeft,
  ArrowUp,
  ArrowRight,
  ArrowDown
}

const translations: { [k: string]: ArrowKeyname } = {
  [ArrowKey.ArrowLeft]: "ArrowLeft",
  [ArrowKey.ArrowRight]: "ArrowRight",
  [ArrowKey.ArrowDown]: "ArrowDown",
  [ArrowKey.ArrowUp]: "ArrowUp"
}

declare global {
  interface ObjectConstructor {
    typedKeys<T>(o: T): Array<keyof T>
  }
}
Object.typedKeys = Object.keys as any

type GetKeystrokes = () => Keystrokes

const decisions = {
  [ArrowKey.ArrowLeft]: (oldCoord: [number, number]): [number, number] => {
    return [oldCoord[0] - 1, oldCoord[1]]
  },
  [ArrowKey.ArrowRight]: (oldCoord: [number, number]): [number, number] => {
    return [oldCoord[0] + 1, oldCoord[1]]
  },
  [ArrowKey.ArrowDown]: (oldCoord: [number, number]): [number, number] => {
    return [oldCoord[0], oldCoord[1] + 1]
  },
  [ArrowKey.ArrowUp]: (oldCoord: [number, number]): [number, number] => {
    return [oldCoord[0], oldCoord[1] - 1]
  }
}

export const moveAdventurer = (key: ArrowKey, store: IStore) => {
  const map = store[Stores.map]
  const adventurer = store[Stores.adventurer]

  const actualPosition = adventurer.hashPosition

  if (!map.get || !map.has) return null

  const oldPosition = map.get(arrayToPlaygroundMapKey(actualPosition))

  if (oldPosition) {
    const newPosition = decisions[key](oldPosition.xy)

    const existFild = map.has(arrayToPlaygroundMapKey(newPosition))

    existFild &&
      newPosition &&
      store.set(Stores.adventurer, {
        hashPosition: newPosition
      })
  }
}

export const getKeystrokes: GetKeystrokes = () => {
  let keystrokes: Keystrokes = new Map()
  Object.typedKeys(translations).map(key => {
    keystrokes.set(translations[key], {
      key: key as ArrowKey
    })
  })
  return keystrokes
}
