import { PlaygroundMap } from "../components/SVGGenerator/types"

export enum Stores {
  playground,
  map,
  adventurer,
  events,
  backpack
}

type Food = {
  meat: number
  water: number
}

export enum EventType {
  CAVE,
  ENEMY,
  STARVE
}

export type SceneEvent = {
  type: EventType
  message: string
}

export enum ArmourType {
  LEATHER,
  IRON,
  SILVER,
  GOLD
}

export enum WeaponType {
  SWORD,
  AXE
}

export enum ADV_EQUIPMENT {
  TORCH
}

export interface IStoreContent {
  [Stores.playground]: {
    test: string
    val: number
  }
  [Stores.backpack]: {
    storeSize: number
    water: number
    armour?: ArmourType
    weapon?: WeaponType
    curedMeat: number
  }
  [Stores.events]: {
    list: SceneEvent[]
  }
  [Stores.map]: Partial<PlaygroundMap>
  [Stores.adventurer]: {
    char: string
    color: string
    hashPosition: [number, number]
    health: number
    level: number
    journeyDay: number
    inventory: Food
  }
}

export type IStoreSet = <T, K extends keyof IStore>(key: K, value: T) => void

export interface IStore extends IStoreContent {
  set<T, K extends keyof IStore>(key: K, value: T): void
  setMap<T, K extends keyof IStore>(key: K, value: T): void
}

export interface IContext {
  context: IStore
}

export const store: IStoreContent = {
  [Stores.backpack]: {
    storeSize: 10,
    water: 10,
    curedMeat: 0
  },
  [Stores.playground]: {
    test: "",
    val: 3
  },
  [Stores.events]: {
    list: []
  },
  [Stores.map]: {},
  [Stores.adventurer]: {
    char: "@",
    color: "red",
    hashPosition: [1, 1],
    journeyDay: 0,
    health: 9,
    level: 0,
    inventory: {
      meat: 0,
      water: 0
    }
  }
}
