import _ from "lodash"
import { GroundType } from "../../components/SVGGenerator/enums"
import { Field, PlaygroundMap } from "../../components/SVGGenerator/types"
import { arrayToPlaygroundMapKey } from "../../components/SVGGenerator/utils"
import {
  EventType, IStore, SceneEvent, Stores
} from "../../context"
import { prepareDiscoverRules } from "./utils"

function probability(n: number) {
  return Math.random() <= n
}

const wilderness: { [index in GroundType]: { probability: number } } = {
  [GroundType.home]: {
    probability: 0
  },
  [GroundType.cave]: {
    probability: 0.8
  },
  [GroundType.nest]: {
    probability: 1
  },
  [GroundType.path]: {
    probability: 0.2
  },
  [GroundType.tree]: {
    probability: 0.4
  },
  [GroundType.water]: {
    probability: 0.3
  }
}

const discoverSurroundings = (
  map: PlaygroundMap,
  range: number,
  field: Field
) => {
  const rules = prepareDiscoverRules(range)

  const surroundings: [number, number][] = rules.map(rule => [
    field.xy[0] + rule.x,
    field.xy[1] + rule.y
  ])

  const res = surroundings.filter(field =>
    map.has(arrayToPlaygroundMapKey(field))
  )

  return res
}

const markAsVisited = (field: Field): Field => ({
  ...field,
  data: { ...field.data, visited: true }
})

const markAsVisible = (field: Field) => ({
  ...field,
  data: { ...field.data, visible: true }
})

export const resolveNewAdventurerPosition = (
  field: Field | undefined,
  context: IStore
) => {
  if (!field) return

  const map = _.cloneDeep(context[Stores.map])

  if (!map || !map.set || !map.get) return

  const surroundings = discoverSurroundings(map as PlaygroundMap, 2, field)

  surroundings.forEach(coords => {
    const fieldKey = arrayToPlaygroundMapKey([coords[0], coords[1]])

    if (!map.get || !map.set) return

    let newField = map.get(fieldKey)

    if (newField) {
      map.set(fieldKey, markAsVisible(newField))
    }
  })

  const newField = markAsVisible(markAsVisited(field))

  map.set(arrayToPlaygroundMapKey(field.xy), newField)
  context.setMap(Stores.map, map)
}

const events = {}

const eventsTranslations: { [index in GroundType]: EventType } = {
  [GroundType.cave]: EventType.CAVE,
  [GroundType.home]: EventType.ENEMY,
  [GroundType.nest]: EventType.ENEMY,
  [GroundType.path]: EventType.ENEMY,
  [GroundType.tree]: EventType.ENEMY,
  [GroundType.water]: EventType.ENEMY
}

const setEvent = (context: IStore, event: SceneEvent) => {
  const prevEvents = context[3].list
  context.set(Stores.events, { list: [...prevEvents, event] })
}

const destinationAction = (field: Field, context: IStore) => {
  const hero = context[Stores.adventurer]
  const groundType = field.data.type

  const modifier = field.data.visited ? -0.1 : 0

  const attack = probability(wilderness[groundType].probability - modifier)

  if (hero.inventory.meat == 0 || hero.inventory.water === 0) {
    setEvent(context, {
      type: EventType.STARVE,
      message: "Hero died cause water of meat."
    })
  } else if (attack) {
    setEvent(context, {
      type: eventsTranslations[groundType],
      message: "Enemy attack to hero"
    })
  }
}

const travelCosts = (field: Field, context: IStore) => {
  const hero = context[Stores.adventurer]

  const {
    inventory: { meat, water },
    journeyDay
  } = hero

  context.set(Stores.adventurer, {
    inventory: 2
  })

  context.set(Stores.adventurer, {
    inventory: {
      meat: meat - (hero.journeyDay % 2 == 0 ? 1 : 0),
      water: water - 1
    },
    journeyDay: journeyDay + 1
  })
}

export const runWildTriggers = (field: Field | undefined, context: IStore) => {
  if (!field) return
  const map = _.cloneDeep(context[Stores.map])
  if (!map || !map.set || !map.get) return
  travelCosts(field, context)
  destinationAction(field, context)
}
