import React from "react"
import Element from "../../components/Element/Element"
import styled from "styled-components"
import { withContext } from "../../context/Store"
import { IContext, Stores } from "../../context"

interface Props extends IContext { }

const StorageContainer = styled.div`
  border: 1px solid black;
  border-radius: 2px;
  width: max-content;
  padding: 5px 15px;
`

const Stockpile: React.FunctionComponent<Props> = ({ context }: Props) => {
  const data = context[Stores.playground]

  return (
    <>
      <Element p={20}>
        Material:
        <StorageContainer>
          <div style={{ display: "flex" }}>
            <Element pr={10}>Wood: </Element>
            <div>{data.val}</div>
          </div>
          <div style={{ display: "flex" }}>
            <Element pr={10}>Food: </Element>
            <div>13</div>
          </div>
          <div style={{ display: "flex" }}>
            <Element pr={10}>Bait: </Element>
            <div>4</div>
          </div>
          <div style={{ display: "flex" }}>
            <Element pr={10}>Meat: </Element>
            <div>40</div>
          </div>
        </StorageContainer>
      </Element>

    </>
  )
}

export default withContext<any>(Stockpile)
