import React, { useState } from "react";
import ActionButtonComponents from "./styled";
import ActionButtonType, { ActionButtonProps } from "./types";
import { prepareClasses } from "./utils";

const {
  ButtonContainer,
  InnerBlock,
  Content,
  Tooltip
} = ActionButtonComponents;

const ActionButton: React.FunctionComponent<ActionButtonType> = ({
  action,
  title,
  tooltip,
  options: { animate = false, duration = 3 }
}: ActionButtonProps) => {
  const [clicked, setClicked] = useState(false);

  const classes = prepareClasses(animate);

  return (
    <ButtonContainer onClick={() => (animate ? setClicked(true) : action())}>
      <InnerBlock
        duration={duration + "s"}
        {...classes(clicked)}
        onAnimationEnd={() => {
          setClicked(false);
          action();
        }}
      >
        <Content>{title}</Content>
      </InnerBlock>
      {tooltip && !clicked && <Tooltip>{tooltip}</Tooltip>}
    </ButtonContainer>
  );
};

export default ActionButton;
