import React, { ReactNode } from "react"
import styled from "styled-components"
import ActionButton from "../../components/ActionButton/ActionButton"

const WrapperButton = styled.div`
  padding-bottom: 10px;
`

const translations = {
  p: "padding",
  pb: "paddingBottom",
  pt: "paddingTop",
  pl: "paddingLeft",
  pr: "paddingRight",
  m: "margin",
  mp: "marginTop",
  mb: "marginBottom"
}

export type ResourceChangeAction = (count: number) => void

export interface Element {
  p?: number
  pt?: number
  pb?: number
  pl?: number
  pr?: number
  children?: string | ReactNode
  style?: {}
  m?: number
}

type ElementComponent = React.FunctionComponent<Element>

const Element: ElementComponent = ({
  p,
  pt,
  pb,
  pl,
  pr,
  children,
  style,
  m
}: Element) => {
  const pstyle = {
    ...(p && { padding: p }),
    ...(pl && { paddingLeft: pl }),
    ...(pr && { paddingRight: pr }),
    ...(pt && { paddingTop: pt }),
    ...(pb && { paddingBottom: pb }),
    ...(m && { margin: m })
  }

  

  return <div style={{ ...pstyle, ...style }}>{children}</div>
}

export default Element
