import React, { useState } from "react"
import styled from "styled-components"
import { IContext, Stores } from "../../../context"
import { withContext } from "../../../context/Store"

const Container = styled.div``

interface Props extends IContext { }

type BriefingComponent = React.FunctionComponent<Props>

const Tooltip = styled.div`
  visibility: hidden;
  background-color: white;
  width: 110px;
  text-align: center;
  padding: 3px 10px;
  border: 1px solid;
  border-radius: 0px;
  box-shadow: 2px 2px 4px;
  position: absolute;
  top: 0;
  left: 130%;
  z-index: 1;
`

const Element = styled.div`
  position: relative;

  :hover {
    ${Tooltip} {
      visibility: visible;
    }
  }
`

const IncDecInput = ({
  inc,
  dec,
  tooltip
}: {
  inc: () => void
  dec: () => void
  tooltip: string
}) => {
  return (
    <Element>
      <button onClick={inc}>+</button>
      <button onClick={dec}>-</button>
      <Tooltip>{tooltip}</Tooltip>
    </Element>
  )
}

const TooltipContent = ({ count }: { count: number }) => {
  return (
    <div>
      <div>Available: {count}</div>
    </div>
  )
}

const Item = ({
  name,
  count,
  inc,
  dec,
  available
}: {
  name: string
  count: number
  available?: number
  inc?: () => void
  dec?: () => void
}) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "space-between", padding: 10 }}
    >
      <div>{name}</div>
      <div>{count}</div>
      {inc && dec && <IncDecInput inc={inc} dec={dec} tooltip="fsdfd" />}
      {available && <TooltipContent count={available} />}
    </div>
  )
}

const SourceList: BriefingComponent = ({ context }: Props) => {
  if (!context.set) return null

  const meat = {
    name: "Food: ",
    stored: 30,
    inventory: context[Stores.backpack].curedMeat,
    inc: (actual: number) => () =>
      context.set(Stores.backpack, { curedMeat: actual + 1 }),
    dec: (actual: number) => () =>
      context.set(Stores.backpack, { curedMeat: actual - 1 })
  }

  const water = {
    name: "Water: ",
    inventory: context[Stores.backpack].water
  }

  return (
    <Container>
      <Item name={water.name} count={water.inventory} />
      <Item
        inc={meat.inc(meat.inventory)}
        dec={meat.dec(meat.inventory)}
        name={meat.name}
        count={meat.inventory}
      />
      {/*  <Item inc={console.log} dec={console.log} name="Axe:" count={2} />
      <Item inc={console.log} dec={console.log} name="Torch:" count={3} />
   */}
    </Container>
  )
}

export default withContext<any>(SourceList)
