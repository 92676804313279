import React, { useState } from "react"
import styled from "styled-components"
import Modal from "../components/Modal/Modal"
import { prepareMapStructure } from "../components/SVGGenerator/utils"
import Forest from "../containers/Forest/Forest"
import Hut from "../containers/Hut/Hut"
import Planet from "../containers/Planet/Planet"
import Stockpile from "../containers/Stockpile/Stockpile"
import { defaultMap } from "../data"
import csdata from "../localisation/cs/index"

const tabs = [
  {
    name: "hut",
    label: "Hut",
    component: Hut,
    data: csdata.hut
  },
  { name: "forest", label: "Forest", component: Forest, data: csdata.hut },
  { name: "planet", label: "Planet", component: Planet, data: csdata.hut }
]

const Ul = styled.ul`
  padding-left: 5px;
`

const Li = styled.li`
  list-style: none;
  font-size: 14px;
  display: inline-block;
  text-decoration: ${(p: { active: boolean }) =>
    p.active ? "underline" : "none"};
  padding-right: 10px;
`

const TabContent = ({
  activeTabIndex,
  componentProps
}: {
  activeTabIndex: string
  componentProps: any
}) => {
  const selectedTab = tabs.find(it => it.name === activeTabIndex)

  if (!selectedTab) return null

  const TabContentComponent = selectedTab.component

  return <TabContentComponent {...componentProps} data={selectedTab.data} />
}

const languages = {
  cs: csdata,
  en: "fdf"
}

const Index: React.FC = () => {
  const count = localStorage.getItem("woodstock")
  const [wood, setWood] = useState(Number(count))
  const [modal, setModal] = useState(false)

  const [food, setFood] = useState(Number(count))

  const [activeTab, setTab] = useState(tabs[0].name)

  const localisation = window.location.pathname.slice(1)

  React.useEffect(() => {
    localStorage.setItem("woodstock", wood.toString())
  }, [wood])

  const [map, { home, mapSize }] = prepareMapStructure(30, defaultMap)

  return (
    <>
      <Ul>
        {tabs.map((it, i) => (
          <Li
            key={`key-${it.name}`}
            active={activeTab === it.name}
            onClick={() => setTab(it.name)}
          >
            {it.label}
          </Li>
        ))}
      </Ul>

      {activeTab && (
        <TabContent
          activeTabIndex={activeTab}
          componentProps={{
            mapSize,
            actions: {
              addWood: (c: number) => setWood(c + wood),
              burnWood: (c: number) => setWood(wood - c),
              addFood: (c: number) => setFood(food + c)
            }
          }}
        />
      )}

      <div style={{ flex: "0 0 240px" }}>
        <Stockpile />
      </div>
      <Modal open={modal} close={() => setModal(!modal)} />
    </>
  )
}

export default Index
