import React from "react"
import { arrayToPlaygroundMapKey } from "../utils"

export type Props = {
  value: any
  itemKey: string
  pointer: { char: string; position: [number, number] }
}

type SVGComponent = React.FunctionComponent<Props>

const SVGText = React.memo<SVGComponent>(
  ({ value, itemKey, pointer }: Props) => {
    const counterPosition =
      itemKey === arrayToPlaygroundMapKey(pointer.position)

    if (counterPosition) {
      return (
        <text {...value.svgProps} fill="#000" key="pointer">
          {pointer.char}
        </text>
      )
    }

    return (
      value.data.visible &&
      !counterPosition && (
        <text {...value.svgProps} key={value.key}>
          {value.char}
        </text>
      )
    )
  }
)

export default SVGText
