import { HutLocal } from "./types"

const hut: HutLocal = {
  base: {
    items: {
      fire: { name: "Zapálit oheň", tooltip: "1 dřevo" }
    }
  },
  build: {
    title: "Postavit",
    items: {
      trap: { name: "Past", tooltip: "10 dřeva" },
      cart: { name: "Košík", tooltip: "6 dřeva" },
      hut: { name: "Chata", tooltip: "500 dřeva" }
    }
  }
}

export { hut }
