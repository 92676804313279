import { GroundType } from "./enums"

export const translations = {
  "<": GroundType.tree,
  "#": GroundType.nest,
  ".": GroundType.path,
  "&": GroundType.cave,
  H: GroundType.home
}

export type FieldTypeString = "<" | "#" | "." | "&" | "H"

export const translateFieldType = (char: string): GroundType | undefined => {
  return translations.hasOwnProperty(char)
    ? translations[char as FieldTypeString]
    : undefined
}
