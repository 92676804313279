import React, { useState, useEffect, useContext } from "react"
import { ArrowKey, moveAdventurer } from "../containers/Planet/MoveLogic"
import { IStore, IContext } from "../context"
import Store, { withContext } from "../context/Store"

type Keystroke = {
  key: ArrowKey
}

export type ArrowKeyname = "ArrowUp" | "ArrowLeft" | "ArrowDown" | "ArrowRight"

export type Keystrokes = Map<ArrowKeyname, Keystroke>

function useKeyEvent(keystrokes: Keystrokes, store: IStore) {
  function downHandler(this: Window, ev: KeyboardEvent) {
    const keys = Array.from(keystrokes.keys())
    keys.forEach(id => {
      const keystroke = keystrokes.get(id)
      keystroke && id == ev.key && store && moveAdventurer(keystroke.key, store)
    })
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  })
}

export default useKeyEvent
