import { PlaygroundMap } from "./types"
import { translateFieldType } from "./translations"
import { GroundType } from "./enums"

export const arrayToPlaygroundMapKey = (coords: [number, number]) => {
  return coords[0].toString() + "-" + coords[1].toString()
}

export const PlaygroundMapKeyToArray = (key: string) => key.split("-")

type ReturnType = [
  PlaygroundMap,
  { home: [number, number] | undefined; mapSize: [number, number] }
]

export const prepareMapStructure = (
  blockSize: number,
  data: string[]
): ReturnType => {
  let map: PlaygroundMap = new Map()
  let home: [number, number] | undefined

  const blockCount = [data.length, data[0].length]
  const mapSize: [number, number] = [
    blockCount[0] * blockSize,
    blockCount[1] * blockSize
  ]

  for (let row = 0; row < blockCount[0]; row++) {
    for (let col = 0; col < blockCount[1]; col++) {
      const char = data[row][col]

      const fieldType = translateFieldType(char)

      if (fieldType == undefined) continue

      if (fieldType === GroundType.home) {
        home = [col, row]
      }

      map.set(arrayToPlaygroundMapKey([col, row]), {
        char,
        xy: [col, row],
        size: [blockSize, blockSize],
        coords: [col * blockSize, row * blockSize],
        key: `key-${char}-${[col * blockSize, row * blockSize]}`,
        data: {
          type: fieldType,
          dangerLevel: 1,
          visible: false,
          visited: false
        },
        svgProps: {
          x: col * blockSize,
          y: row * blockSize,
          fill: "darkgrey"
        }
      })
    }
  }

  return [map, { home, mapSize }]
}
