import React, { useState } from "react"
import styled from "styled-components"
import { IContext } from "../../context"
import { withContext } from "../../context/Store"
import Map from "./Map"
import Briefing from "./Briefing/Briefing"

export type ResourceChangeAction = (count: number) => void

export type PlanetProps = IContext & {
  mapSize: [number, number]
}

enum Tabs {
  adventure,
  brief
}

const transformTabs = {
  [Tabs.adventure]: -600,
  [Tabs.brief]: 0
}

const Container = styled.div`
  width: 600px;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  transition: 300ms ease-in-out;

  transform: ${(props: { tab: Tabs }) =>
    `translate(${transformTabs[props.tab]}px, 0px)`};
`

type HutComponent = React.FunctionComponent<PlanetProps>

const Planet: HutComponent = ({ context, mapSize }: PlanetProps) => {
  const [adventure, setAdventure] = useState(false)

  return (
    <Container>
      <Content tab={adventure ? Tabs.adventure : Tabs.brief}>
        <Briefing startAdventure={() => setAdventure(true)} />
        <Map mapSize={mapSize} />
      </Content>
    </Container>
  )
}

export default withContext(Planet)
