import React, { useContext } from "react"
import styled from "styled-components"
import ActionButton from "../../components/ActionButton/ActionButton"
import Element from "../../components/Element/Element"
import { IStore, IContext, Stores } from "../../context"
import Store, { withContext } from "../../context/Store"
import Modal from "../../components/Modal/Modal"
import { HutLocal } from "../../localisation/cs/Hut/types"
import ActionSection from "../../components/ActionSection/ActionSection"

const WrapperButton = styled.div`
  padding-bottom: 10px;
`

export type ResourceChangeAction = (count: number) => void

export type HutProps = IContext & {
  actions: {
    addWood: ResourceChangeAction
    burnWood: ResourceChangeAction
  }
  data: HutLocal
}

type HutComponent = React.FunctionComponent<HutProps>

const Hut: HutComponent = ({
  actions: { addWood, burnWood },
  context,
  data
}: HutProps) => {
  console.log(data)
  return (
    <>
      <WrapperButton>
        <ActionButton
          action={() => {
            burnWood(1)
          }}
          title={data.base.items.fire.name}
          tooltip={data.base.items.fire.tooltip}
          options={{
            animate: true,
            duration: 0.3
          }}
        />
      </WrapperButton>

      <ActionSection title={data.build.title} actionButtons={[]} />

      <Element pt={10} pb={15} style={{ fontSize: 13 }}>
        {data.build.title}:
      </Element>
      <WrapperButton>
        <ActionButton
          action={() => {
            burnWood(6)
          }}
          title={data.build.items.trap.name}
          tooltip={data.build.items.trap.tooltip}
          options={{}}
        />
      </WrapperButton>
      <WrapperButton>
        <ActionButton
          action={() => {
            burnWood(50)
          }}
          title={data.build.items.cart.name}
          tooltip={data.build.items.cart.tooltip}
          options={{}}
        />
      </WrapperButton>
      <WrapperButton>
        <ActionButton
          action={() => {
            burnWood(100)
            context.set(Stores.playground, { test: "fds" })
          }}
          title={data.build.items.hut.name}
          tooltip={data.build.items.hut.tooltip}
          options={{}}
        />
      </WrapperButton>
    </>
  )
}

export default withContext(Hut)
