import { Stores, IStoreContent } from "./context"

export const defaultMap = [
  "<<<<<<<............<",
  "<<<<<<<<<.......<<<<",
  "<<#<<<<<<.....<<<<<.",
  "<<<<<&<<...<<<<<<...",
  "<<<<<<<<............",
  "<<<<<.....<<<<...#..",
  "<<&<<...<<<<<.......",
  "<<<....<<#<<........",
  "<...<..<<<<<<<<<<<<.",
  "..<<<<......<<<<<<<.",
  ".<<&<.......<<&<#<..",
  "..<<<.....#.<<<<<<<.",
  "...<.........<<<<<..",
  ".....&.......<<<<<<.",
  "<<<<......H..<<&<<<.",
  "<<<<<<<<......<<<<..",
  ".<<<<<<<<<<<<.......",
  "..#<<<<<<<<<#<<.....",
  ".......<<<<<<<<<<<..",
  "...........<<<<<<<.."
]

export const map2 = [
  "<<<<<<<............<.........#",
  "<<<<<<<<<......#<<<<..........",
  "<<#<<<<<<.....<<<<<.....#.....",
  "<<<<<&<<...<<<<<<............<",
  "<<<<<<<<...................<<<",
  "<<<<<.....<<<<...#........<<<<",
  "<<&<<...<<<<<............<<<<<",
  "<<<....<<#<<............<<<<<<",
  "<...<..<<<<<<<<<<<<.....<<<<<<",
  "..<<<<......<<<<<<<.....<<<&<<",
  ".<<&<.......<<&<#<......<<<<<.",
  "..<<<.....#.<<<<<<<.......<<..",
  "...<.........<<<<<............",
  ".....&.......<<<<<<....#......",
  "<<<<......H..<<&<<<...........",
  "<<<<<<<<<.....<<<<............",
  ".<<<<<<<<<<<<.................",
  "..#<<<<<<<<<#<<...............",
  ".......<<<<<<<<<<<...........#",
  "......<<<<.<<<<<<<.......<<...",
  "......<<<<.............<<<<...",
  "#....<<<<.............<<<<<...",
  "....<<<<<............<<<<<....",
  "...<<<#<<...........<<<<#<....",
  "...<<<<<<<<.....#....<<<<.....",
  "...<<<<<<#<...................",
  "..<<<<<<<<<.................<<",
  "..<<&&<<<<.................<<<",
  "......................#<<<<<<<",
  "....#........#......<<<<<<<<<#"
]

export const defaultAdventurer: IStoreContent[Stores.adventurer] = {
  char: "@",
  color: "yellow",
  hashPosition: [1, 1],
  health: 9,
  journeyDay: 0,
  level: 0,
  inventory: {
    water: 20,
    meat: 10
  }
}

// https://notimetoplay.itch.io/ascii-mapper
