import React, { useState } from "react"
import styled from "styled-components"
import "./App.css"
import { prepareMapStructure } from "./components/SVGGenerator/utils"
import Forest from "./containers/Forest/Forest"
import Hut from "./containers/Hut/Hut"
import Planet from "./containers/Planet/Planet"
import { store, Stores } from "./context"
import { StoreProvider } from "./context/Store"
import { defaultAdventurer, defaultMap } from "./data"
import Layout from "./Layout"
import csdata from "./localisation/cs/index"
import Index from "./pages"

const tabs = [
  {
    name: "hut",
    label: "Hut",
    component: Hut,
    data: csdata.hut
  },
  { name: "forest", label: "Forest", component: Forest, data: csdata.hut },
  { name: "planet", label: "Planet", component: Planet, data: csdata.hut }
]

const Ul = styled.ul`
  padding-left: 5px;
`

const Li = styled.li`
  list-style: none;
  display: inline-block;
  text-decoration: ${(p: { active: boolean }) =>
    p.active ? "underline" : "none"};
  padding-right: 10px;
`

const TabContent = ({
  activeTabIndex,
  componentProps
}: {
  activeTabIndex: string
  componentProps: any
}) => {
  const selectedTab = tabs.find(it => it.name === activeTabIndex)

  if (!selectedTab) return null

  const TabContentComponent = selectedTab.component

  return <TabContentComponent {...componentProps} data={selectedTab.data} />
}

const languages = {
  cs: csdata,
  en: "fdf"
}

const App: React.FC = () => {
  const count = localStorage.getItem("woodstock")
  const [wood, setWood] = useState(Number(count))


  React.useEffect(() => {
    localStorage.setItem("woodstock", wood.toString())
  }, [wood])

  const [map, { home, mapSize }] = prepareMapStructure(30, defaultMap)

  return (
    <div className="App">
      <StoreProvider
        defaultValue={{
          ...store,
          [Stores.map]: map,
          [Stores.adventurer]: {
            ...defaultAdventurer,
            ...(home && { hashPosition: home })
          }
        }}
      >
        <Layout>
          <Index />
        </Layout>
      </StoreProvider>
    </div>
  )
}

export default App
