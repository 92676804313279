import React, { useMemo } from "react"
import styled from "styled-components"
import { prepareMapStructure, arrayToPlaygroundMapKey } from "./utils"
import { PlaygroundMap, Field, FieldType } from "./types"
import ArrayKeyedMap from "../../utils/ArrayKeyedMap"
import SVGText from "./SVGText/SVGText"

export type ResourceChangeAction = (count: number) => void

export type PlanetProps = {
  data: Partial<PlaygroundMap>
  size: { width: number; height: number }
  offset: [number, number]
  pointer: {
    char: string
    position: [number, number]
  }
}

type HutComponent = React.FunctionComponent<PlanetProps>

const SVGGenerator: HutComponent = React.memo(
  ({ data, size, offset, pointer }: PlanetProps) => {
    if (!data.keys || !data.get) return null

    const keys = Array.from<string>(data.keys() as any) || []

    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" {...size}>
          <path d={`M-1-1h${size.width}v${size.height}H-1z`} fill="#f5f5f5" />
          <g transform={`translate(${offset[0]},${offset[1]})`} key="fdf">
            {keys.map(
              key =>
                data.get &&
                data.get(key) && (
                  <SVGText
                    key={key}
                    value={data.get(key)}
                    itemKey={key}
                    pointer={pointer}
                  />
                )
            )}
          </g>
        </svg>
      </>
    )
  }
)

// TODO: rework - make separate component for adventurer and for map field

export default SVGGenerator
