import styled, { keyframes, css } from "styled-components"

const Tooltip = styled.div`
  visibility: hidden;
  background-color: white;
  width: 110px;
  text-align: center;
  padding: 3px 10px;
  border: 1px solid;
  border-radius: 0px;
  box-shadow: 2px 2px 4px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 100%;
  left: 5px;
  z-index: 1;
`

const ButtonContainer = styled.div`
  height: 25px;
  width: 100px;
  background: #000;
  padding: 1px;
  position: relative;
  &:hover {
    ${Tooltip} {
      visibility: visible;
    }
  }
`

const animation = keyframes`
0% {
background-position: 0% 50%;
}
100% {
    background-position: 100% 50%;
}
`

const InnerBlock = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  text-align: center;

  &.animationClass {
    background: linear-gradient(
      90deg,
      rgba(194, 194, 194, 1) 52%,
      rgba(255, 255, 255, 0.9346113445378151) 52%,
      rgba(255, 255, 255, 1) 100%
    );
    background-size: 200% 200%;
    animation-duration: ${(props: { duration: string }) => props.duration};
    animation-timing-function: linear;
    animation-name: ${animation};
  }
`

const Content = styled.div`
  padding-top: 5px;
  font-size: 12px;
`

const ActionButtonComponents = {
  Content,
  ButtonContainer,
  InnerBlock,
  Tooltip
}

export default ActionButtonComponents
