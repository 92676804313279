import React, { ReactChild } from "react"
import "./App.css"

const Layout: React.FunctionComponent<{ children: ReactChild }> = ({
  children
}: {
  children: React.ReactChild
}) => {
  return <div>{children}</div>
}

export default Layout
