import React from "react"
import styled from "styled-components"
import ActionButton from "../../components/ActionButton/ActionButton"
import Element from "../../components/Element/Element"

const WrapperButton = styled.div`
  padding-bottom: 10px;
`

export type ActionSectionButtonType = {
  action: () => void
  title: string
  tooltip: string
  time?: number
}

export type ActionSectionProps = {
  actionButtons: ActionSectionButtonType[]
  title: string
}

type ActionSectionComponent = React.FunctionComponent<ActionSectionProps>

const ActionSection: ActionSectionComponent = ({
  title,
  actionButtons
}: ActionSectionProps) => {
  return (
    <>
      <Element pt={10} pb={15} style={{ fontSize: 13 }}>
        {title}:
      </Element>
      {actionButtons.map(action => (
        <WrapperButton>
          <ActionButton {...action} options={{}} />
        </WrapperButton>
      ))}
    </>
  )
}

export default ActionSection
