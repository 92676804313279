import React from "react"
import { IContext, Stores } from "../../context"
import { withContext } from "../../context/Store"
import Element from "../Element/Element"
import { GroundType } from "../SVGGenerator/enums"
import { arrayToPlaygroundMapKey } from "../SVGGenerator/utils"

type Props = IContext & {}

type HutComponent = React.FunctionComponent<Props>

const AdventureBar: HutComponent = ({ context }: Props) => {
  const {
    set,
    [Stores.adventurer]: {
      hashPosition,
      journeyDay,
      health,
      inventory: { meat, water }
    }
  } = context
  const map = context[Stores.map]
  const field = map.get && map.get(arrayToPlaygroundMapKey(hashPosition))
  const fieldType = field && GroundType[field.data.type]

  return (
    <Element
      p={10}
      style={{
        border: "1px solid",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <div>meat: {meat}</div> <div>water: {water}</div>
      <div>health: {health}</div>
      <div>Days on journey: {journeyDay}</div>
      <div>Actual field: {fieldType}</div>
    </Element>
  )
}

export default withContext<any>(AdventureBar)
