import React, { useState } from "react"
import styled from "styled-components"
import { IContext } from "../../../context"
import { withContext } from "../../../context/Store"
import Element from "../../../components/Element/Element"
import SourceList from "./SourceList"
import ActionButton from "../../../components/ActionButton/ActionButton"

const Container = styled.div`
  width: 600px;
  padding-top: 10px;
`

const Block = styled.div`
  width: 600px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
`

const Backapacker = styled.div`
  border: 1px solid black;
  width: 300px;
`

interface Props extends IContext {
  startAdventure: () => void
}

type BriefingComponent = React.FunctionComponent<Props>

const Briefing: BriefingComponent = ({ context, startAdventure }: Props) => {
  return (
    <Container>
      Backpack:
      <Block>
        <Backapacker>
          <SourceList />
        </Backapacker>
      </Block>
      <ActionButton options={{}} title="embark" action={startAdventure} />
    </Container>
  )
}

export default withContext<any>(Briefing)
